import MainRoutes from "./router/mainRoutes";
import "./App.css";
import MainDrawer from "./components/mainDrawer";

function App() {
  return (
    <div>
      <MainDrawer />
    </div>
  );
}

export default App;
