import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import httpServices from "../auth/httpServices";

const columns = [
  { field: "firstName", headerName: "First name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  {
    field: "email",
    headerName: "E-mail",
    type: "string",
    width: 230,
  },
];

function UsersPage() {
  const [users, setUsers] = useState([]);

  const checkedUsers = users.filter((user) => user.checked === true);

  useEffect(() => {
    httpServices.get("/users").then((results) => {
      const fiteredResults = results.data.map((user) => ({
        id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      }));
      setUsers(fiteredResults);
    });
  }, []);

  function handleRowCheck(data) {
    const filteredUsers = users.map((user) => {
      if (user.id === data.row.id) {
        return { ...user, checked: !user.checked };
      } else {
        return user;
      }
    });
    setUsers(filteredUsers);
  }

  function handleDelete() {
    const ids = checkedUsers.map((user) => user.id);
    let notDeletedUsers = [];
    for (let i = 0; i < ids.length; i++) {
      if (i === 0) {
        const filtered = users.filter((user) => user.id !== ids[i]);
        notDeletedUsers = filtered;
      } else {
        const filtered = notDeletedUsers.filter((user) => user.id !== ids[i]);
        notDeletedUsers = filtered;
      }
    }

    httpServices
      .delete("/user", { data: ids })
      .then((results) => {
        setUsers(notDeletedUsers);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="users-page-container">
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={users}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          onCellClick={handleRowCheck}
        />
      </div>
      <Snackbar
        open={checkedUsers.length > 0}
        autoHideDuration={1000}
        message={`Total users for deleting ${checkedUsers.length}`}
        action={
          <Button variant="text" onClick={handleDelete}>
            Delete
          </Button>
        }
      />
    </div>
  );
}

export default UsersPage;
