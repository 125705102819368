import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import httpServices from "../auth/httpServices";

const columns = [
  { field: "id", headerName: "Order ID", width: 130 },
  {
    field: "createdDate",
    headerName: "Created Date",
    width: 200,
    renderCell: (params) => dayjs(params.value).format("DD/MMM/YYYY HH:mm"),
  },
  {
    field: "updatedDate",
    headerName: "Updated Date",
    width: 200,
    renderCell: (params) => dayjs(params.value).format("DD/MMM/YYYY HH:mm"),
  },
  { field: "status", headerName: "Status", width: 130 },
  { field: "total", headerName: "Total", width: 130 },
];

function Orders() {
  const [orders, setOrders] = useState([]);
  const [checkedOrdersIds, setCheckedOrdersIds] = useState([]);

  useEffect(() => {
    httpServices.get("/orders").then((results) => {
      const fiteredResults = results.data.map((order) => ({
        _id: order._id,
        id: order.orderId,
        createdDate: order.createdAt,
        updatedDate: order.updatedAt,
        status: order.status,
        total: order.grandTotal,
      }));
      setOrders(fiteredResults);
    });
  }, []);

  function handleRowCheck(data) {
    const filteredOrders = data.map(
      (orderId) => orders.find((order) => order.id === orderId)._id
    );
    setCheckedOrdersIds(filteredOrders);
  }

  function handleDelete() {
    let notDeletedOrders = [];
    for (let i = 0; i < checkedOrdersIds.length; i++) {
      if (i === 0) {
        const filtered = orders.filter(
          (order) => order._id !== checkedOrdersIds[i]
        );
        notDeletedOrders = filtered;
      } else {
        const filtered = notDeletedOrders.filter(
          (order) => order._id !== checkedOrdersIds[i]
        );
        notDeletedOrders = filtered;
      }
    }

    httpServices
      .delete("http://localhost:5000/orders", { data: checkedOrdersIds })
      .then((results) => {
        setOrders(notDeletedOrders);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="orders-page-container">
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={orders}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          onSelectionModelChange={handleRowCheck}
        />
      </div>
      <Snackbar
        open={checkedOrdersIds.length > 0}
        autoHideDuration={1000}
        message={`Total orders for deleting : ${checkedOrdersIds.length}`}
        action={
          <Button variant="text" onClick={handleDelete}>
            Delete
          </Button>
        }
      />
    </div>
  );
}

export default Orders;
