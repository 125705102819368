import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from '@mui/icons-material/Group';
import { useNavigate } from "react-router-dom";


function DrawerListItems({ isDrawerOpen }) {
  const navigate = useNavigate();
  return (
    <List>
      <ListItem
        disablePadding
        sx={{ display: "block" }}
        onClick={() => {
          navigate("/");
        }}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isDrawerOpen ? "initial" : "center",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isDrawerOpen ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText
            primary="Dashboard"
            sx={{ opacity: isDrawerOpen ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        disablePadding
        sx={{ display: "block" }}
        onClick={() => {
          navigate("/orders");
        }}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isDrawerOpen ? "initial" : "center",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isDrawerOpen ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            <InboxIcon />
          </ListItemIcon>
          <ListItemText
            primary="Orders"
            sx={{ opacity: isDrawerOpen ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        disablePadding
        sx={{ display: "block" }}
        onClick={() => {
          navigate("/users");
        }}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isDrawerOpen ? "initial" : "center",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isDrawerOpen ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            <GroupIcon />
          </ListItemIcon>
          <ListItemText
            primary="Users"
            sx={{ opacity: isDrawerOpen ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
    </List>
  );
}

export default DrawerListItems;
