import { Routes, Route, Link } from "react-router-dom";
import Login from "../pages/login-page";
import Main from "../pages/main";
import Orders from "../pages/orders";
import UsersPage from "../pages/usersPage";

function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default MainRoutes;
